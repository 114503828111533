<template>
  <div v-if="isOpen">
    <b-modal
      centered
      :title="isUpdate ? 'Update Event Mission' : 'Create Event Mission'"
      v-model="isOpen"
      id="modal-xl"
      size="xl"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer ref="createForm" v-slot="{ handleSubmit }" class="mr-4 ml-4">
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-row>
              <b-col cols="12">
                <b-form-group label="Mission Name" label-for="h-mission-name" label-cols-md="3">
                  <ui-component :component-data="model.name" class="border" />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Mission Description"
                  label-for="h-mission-description"
                  label-cols-md="3"
                >
                  <ui-component :component-data="model.description" class="border" />
                </b-form-group>
              </b-col>
            </b-row>

            <validation-provider
              name="Mission key"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group label="Mission key" label-for="h-reward" label-cols-md="3">
                <b-form-input
                  v-model="model.mission_key"
                  :state="getValidationState(validationContext)"
                  placeholder="watch_ads"
                />
                <b-form-invalid-feedback
                  id="input-category-name-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="level"
              v-slot="validationContext"
              :rules="{ required: true, numeric:true }"
            >
              <b-form-group label="Level" label-for="h-reward" label-cols-md="3">
                <b-form-input
                  v-model="model.level"
                  :state="getValidationState(validationContext)"
                  placeholder="10"
                />
                <b-form-invalid-feedback
                  id="input-category-name-feedback"
                >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <template v-if="!isWatchAdsMission">
              <div class="mb-2">
                <h3>Rewards</h3>
              </div>
              <b-row v-for="(item, index) in model.rewards" :key="index">
                <b-col sm="3">
                  <span>{{ getUserType(item.user_type) }}</span>
                </b-col>
                <b-col sm="9">
                  <b-form-group label="Reward Type" label-for="h-reward" label-cols-md="3">
                    <span class="d-block" style="padding-top: calc(0.438rem + 1px);">{{ item.type }}</span>
                  </b-form-group>
                  <validation-provider
                    name="Amount"
                    v-slot="validationContext"
                    :rules="{ required: true, double:true }"
                  >
                    <b-form-group label="Amount" label-for="h-amount" label-cols-md="3">
                      <b-form-input
                        v-model="item.amount"
                        :state="getValidationState(validationContext)"
                        placeholder="Amount"
                      />
                      <b-form-invalid-feedback
                        id="input-category-name-feedback"
                      >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button :disabled="!!isLoading" type="submit" variant="primary" @click="onSave">
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import missionRewardService from "./../../../mission_reward/service";
import defaultObject from "../../defaultObject.js";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { categories, selectRewards, MISSION_KEYS, DEFAULT_MISSION_REWARDS, USER_TYPES } from './../../constants'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
      missionRewards: [],
      categories,
    };
  },

  computed: {
    isWatchAdsMission() {
      return this.model.mission_key === MISSION_KEYS.WATCH_ADS
    }
  },

  watch: {
    'model.mission_key'(newValue) {
      if (newValue === MISSION_KEYS.WATCH_ADS) {
        this.model.rewards = null
        this.isChangedMissionKey = 1
      } else if (this.isChangedMissionKey) {
        this.model.rewards = JSON.parse(JSON.stringify(DEFAULT_MISSION_REWARDS))
        this.isChangedMissionKey = 0
      }
    }
  },

  async created() {
    this.getMissionRewards()
  },

  methods: {
    async getMissionRewards() {
      const { data } = await missionRewardService.getAll()
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map(reward => {
          return {
            text: reward.type,
            value: reward.type,
          }
        })
        return
      }

      this.missionRewards = selectRewards
    },

    getUserType(user_type) {
      return Object.keys(USER_TYPES)[user_type - 1] ?? ''
    },

    openCreateModal() {
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal(eventMission) {
      this.model = eventMission;
      this.isOpen = true;
      this.isUpdate = true;
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isOpen = true;
        this.isLoading = true;
        if (!this.isUpdate) {
          try {
            let response = await service.create(this.model);
            if (response.data.type === "DATA") {
              this.$store.dispatch("pushSuccessNotify", {
                text: "Event mission created!",
              });
            } else {
              this.$store.dispatch("pushErrorNotify", {
                text: "Error created event mission!",
              });
            }
          } catch (e) {
            this.isLoading = false;
          }
        } else {
          try {
            await service.update({ data: JSON.stringify(this.model) });
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.$emit("loadData");
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
      this.model = JSON.parse(JSON.stringify(defaultObject));
      this.$emit("loadData");
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    addMissionReward() {
      this.model.rewards.push({
        type: '',
        amount: 0
      })
    },

    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteMissionReward(index);
          }
        });
    },

    deleteMissionReward(index) {
      let newRewards = []
      let i = 0
      for (let reward of this.model.rewards) {
        if (i++ !== index) {
          newRewards.push(reward)
        }
      }
      this.model.rewards = newRewards
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
.add-mission-reward {
  width: 20px;
  height: 20px;
  color: #207a1f;
  display: flex;
  float: right;
}
.remove-mission-reward {
  width: 20px;
  height: 20px;
  color: #cf2020;
}
</style>
