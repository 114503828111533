import api from '@/libs/axios'

export default {
  async create(payload) {
    const response = await api.post('/admin/event-missions', payload)
    return response.data.data.new_document || null
  },
  async update(payload) {
    return await api.put('/admin/event-missions', payload)
  },
  async delete(payload) {
    return await api.delete('/admin/event-missions', {
      data: payload,
    })
  },
  async getList(params) {
    return await api.get('/admin/event-missions', {
      params,
    })
  },
  async get(params) {
    let response = await api.get('/admin/event-missions/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async updateDisplayOrder(payload) {
    return await api.post('/admin/event-missions/update-display-order', payload)
  },
  async getPublicEventMissionDate() {
    let response = await api.get('/admin/configs/detail', { params: { key: 'PUBLIC_EVENT_MISSION_DATE' } })
    return response.data.data.document || null
  },
  async updatePublicEventMissionDate(payload) {
    let response = await api.put('/admin/configs', payload)
    return response
  },
}
