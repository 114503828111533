export default {
  name: {
    type: 'translatable-text',
    value: null,
    meta: {
      default_lang: 'en',
    },
    translations: [{ language: 'vi', value: '' }],
  },
  description: {
    type: 'translatable-text',
    value: null,
    meta: {
      default_lang: 'en',
    },
    translations: [{ language: 'vi', value: '' }],
  },
  rewards: [
    {
      user_type: 1,
      type: 'TOKEN',
      amount: 0.01,
    },
    {
      user_type: 2,
      type: 'TOKEN',
      amount: 0.1,
    },
  ]
}
