<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Config Public Mission"
      v-model="isOpen"
      id="modal-xl"
      size="md"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <validation-provider
              name="Start date"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group label="Start date" label-for="h-public-date" label-cols-md="5">
                <b-form-datepicker :v-model="model.value.start" :state="getValidationState(validationContext)" style="display: none !important" />
                <date-picker v-model="model.value.start" type="datetime" placeholder="Start date" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback id="h-public-date">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="End date"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group label="End date" label-for="h-public-date" label-cols-md="5">
                <b-form-datepicker :v-model="model.value.end" :state="getValidationState(validationContext)" style="display: none !important" />
                <date-picker v-model="model.value.end" type="datetime" placeholder="End date" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback id="h-public-date">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button
          :disabled="!!isLoading"
          type="submit"
          variant="primary"
          @click="onSave"
        >
          <b-spinner v-if="isLoading" class="mr-1" small />
          <span>Save</span>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
      isLoading: false,
      model: {},
    };
  },

  async created() {
    await this.getPublicEventDate()
  },

  methods: {
    async getPublicEventDate() {
      this.model = await service.getPublicEventMissionDate()
      if (this.model.value) {
        this.model.value.start = new Date(this.model.value.start)
        this.model.value.end = new Date(this.model.value.end)
      }
    },

    async openCreateModal() {
      this.model = {}
      this.isOpen = true;
      this.isUpdate = false;
    },

    async openUpdateModal() {
      await this.getPublicEventDate()
      this.isOpen = true;
      this.isUpdate = true;
    },

    checkValidConfigDate() {
      if ((new Date(this.model.value.start) - new Date(this.model.value.end)) > 0) {
        this.$store.dispatch("pushErrorNotify", {
          text: "Start date must be less than end date!",
        });

        return false
      }

      return true
    },

    async onSave() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        if (!this.checkValidConfigDate()) {
          return
        }
        this.isOpen = true;
        this.isLoading = true;
        if (this.isUpdate) {
          try {
            await service.updatePublicEventMissionDate(this.model);
          } catch (e) {
            this.isLoading = false;
          }
        }
        this.isLoading = false;
        this.isOpen = false;
        this.getPublicEventDate()
      }
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
