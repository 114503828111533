module.exports = {
    selectRewards: [
        {
            text: "Token",
            value: "TOKEN",
        },
    ],
    MISSION_KEYS: {
        WATCH_ADS: 'watch_ads'
    },
    USER_TYPES: {
        FREE_USER: 1,
        PAID_USER: 2,
    },
    DEFAULT_MISSION_REWARDS: [
        {
            user_type: 1,
            type: 'TOKEN',
            amount: 0.01,
        },
        {
            user_type: 2,
            type: 'TOKEN',
            amount: 0.1,
        },
    ]
}
